import autotaskAPI from '../communications/interceptors/apiInterceptorArchive'
export const autotaskAPIArchiveMixin = {
  data () {
    return {
      ticketGetCount: 0,
      alreadyGettingTickets: false,
      archivedTickets: []
    }
  },
  methods: {
    getHeaders () {
      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
    },
    ticketsGetArchived: async function (keyword, ticketNumber, site, customerReference, activityFrom, activityTo, createdFrom, createdTo, completedFrom, completedTo) {
      console.log('Top of function - keyword: ' + keyword + ', ticketNumber: ' + ticketNumber + ', site: ' + site + ', customerReference: ' + customerReference + ', activityFrom: ' + activityFrom + ', activityTo: ' + activityTo + ', createdFrom: ' + createdFrom + ', createdTo: ' + createdTo + ', completedFrom: ' + completedFrom + ', completedTo: ' + completedTo)
      if (!this.alreadyGettingTickets) {
        this.archivedTickets = []
        this.alreadyGettingTickets = true
        if (site.length === 0) {
          var sites = this.$store.getters.sites
          if (sites && sites.length > 0) {
            // We are only allowed 500 "or" conditions, and some are used in other filter expressions. If
            // we have more than 300 sites, we must hit the autotask API repeatedly and build up a list
            // of results. (The previous threshold of 485 sites returns an internal server error from the
            // autotask endpoint)
            var sliceSize = 300
            var siteCount = sites.length
            var siteList = ''
            var sitesAddedToList = 0
            // Process every site in turn
            for (var sitePtr = 0; sitePtr < siteCount; sitePtr++) {
              // If we have a list of sites to process and have either reached the last site
              // on the list, or have added the threshold number of sites to the site list,
              // hit the API to get the tickets for those sites
              if ((sitePtr === (siteCount - 1) || sitesAddedToList === sliceSize) && siteList !== '') {
                var payload = {
                  siteList: siteList,
                  keyword: keyword,
                  ticketNumber: ticketNumber,
                  customerReference: customerReference,
                  activityFrom: activityFrom,
                  activityTo: activityTo,
                  createdFrom: createdFrom,
                  createdTo: createdTo,
                  completedFrom: completedFrom,
                  completedTo: completedTo
                }

                await autotaskAPI.post('/autotaskapi/tickets/sitelist/archive', payload, this.getHeaders())
                  .then(res => {
                    for (let i = 0; i < res.data.length; i++) {
                      this.archivedTickets.push(res.data[i])
                    }
                  })
                  .catch((err) => {
                    // eslint-disable-next-line
                    console.log('Error getting tickets with error: ' + err)
                  })

                // We know that a search for a specific ticket will only yield one
                // result, so if we have that result, we can quit at this point
                if (ticketNumber !== null && ticketNumber !== undefined && ticketNumber.length > 0) {
                  if (this.archivedTickets.length > 0) {
                    sitePtr = siteCount
                  }
                }
                if (this.archivedTickets.length > 0 && ticketNumber !== null && ticketNumber !== undefined && ticketNumber.length > 0) {
                  sitePtr = siteCount
                } else {
                  // Otherwise reset the site list variables for the next "batch" of sites
                  sitesAddedToList = 0
                  siteList = ''
                }
              }

              // Add the present site to the list if we have not found what we're looking for and are quitting the loop
              if (sitePtr < siteCount) {
                if (siteList !== '') {
                  siteList += ','
                }
                siteList += sites[sitePtr].id
                sitesAddedToList += 1
              }
            }
          }
        }
        this.alreadyGettingTickets = false
        return this.archivedTickets
      }
    }
  }
}
