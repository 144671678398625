import axios from 'axios'
import { AuthEventBus } from '../../events/authEvents'

// The purpose of this repository is to centralise all system api communications
let instance = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 100000
})

instance.interceptors.request.use((config) => {
  // Add mulitple headers for cache removal, there were some intermittent issues were different browsers thus I've included all items
  config.headers['Cache-Control'] = 'no-cache'
  config.headers['Pragma'] = 'no-cache'
  config.headers['Expires'] = 'Sat, 01 Jan 2000 00:00:00 GMT'
  config.headers['Content-Type'] = 'application/json; charset=UTF-8'
  config.headers['Application'] = 'PortalUI'

  // Retrieve tokens from local storage
  const token = localStorage.getItem('token')
  const refresh = localStorage.getItem('refreshToken')
  if (token === null || token === '' || refresh === null || refresh === '') {
    if (this) {
      this.$store.commit('showMessage', { content: 'Your logon credentials are invalid or expired', color: 'red', timeout: 3000 })
    }
    AuthEventBus.$emit('page-load', '')
    return config
  }
  config.headers['Authorization'] = `Bearer ${token}`
  config.headers['Refresh'] = refresh
  return config
}, error => {
  console.log('request error', error)
  if (error.status === 404) {
    // 404 is a not found error.  This may need to be changed at a later date
    console.log('Could not find the function on the server, please try again later')
  }
  // Reject any other error
  return Promise.reject(error)
})

instance.interceptors.response.use((response) => {
  return response
}, error => {
  // eslint-disable-next-line
  console.log('response error', error)
  // A 401 error is an unauthorised error, this will usually be due to an expired authenticaion token
  if (error.response.status === 401) {
    // Map original request to a constant
    const originalRequest = error.config
    const requestingUser = localStorage.getItem('username')
    // Validate this is the first time a refresh token has attempted to be used
    if (!originalRequest._retry) {
      // Set the original request to _retry (i.e. an attempt to refresh has been made previously)
      originalRequest._retry = true
      // Check if the refresh is valid
      return instance.post('/refreshtoken/refresh', { username: requestingUser })
        .then((response) => {
          // If the refresh is valid emit an event whcih will update all tokens in local storage
          AuthEventBus.$emit('refresh-updated', response.data)
          // Re-run original request
          return instance(originalRequest)
        })
    }
    // If the refresh token is valid but hs been revoked the session has been forcably cancelled.
    AuthEventBus.$emit('session-closed', 'Your session has been cancelled')
  } else if (error.response.status === 440) {
    // 440 is returned for an expired session and refresh
    AuthEventBus.$emit('session-closed', 'Your session has expired')
  } else if (error.response.status === 404) {
    // A 404 is a not found error, this could happen if the server in unavaliable
    if (this) {
      this.$store.commit('showMessage', { content: 'There appears to be a connection problem, please try again later', color: 'red', timeout: 3000 })
    }
  }
  // If the code gets here the request was invalid and the error should be persisted
  return Promise.reject(error)
}
)
export default instance
