<template>
  <div>
    <div
      v-if="viewAreaTicketsPermission"
      :class="{ appLoggedOff : isLoggedOff }"
      class="appSectionContainer"
    >
      <div
        class="appSectionHeader"
      >
        <div class="openTickets">
          <div
            class="titleContainer"
          >
            <p class="titleSection">{{ reportTitle }}</p>
          </div>
          <div class="filterShow">
            <v-btn
              id="btnShowFilter"
              :title="[showFilter ? 'Hide Filter' : 'Show Filter']"
              flat
              icon
              @click="toggleFilter"
            >
              <v-icon style="color:black;font-size:2em;">mdi-filter</v-icon>
            </v-btn>
          </div>
          <TextButton
            v-if="viewAreaTicketsPermission"
            :button-tabindex="3"
            button-id="btnOpenTickets"
            title="Back To Current Tickets"
            text="Back"
            @click="openTicketPage()"
          />
        </div>
      </div>
      <div
        v-show="showFilter"
        class="filterContainer"
      >
        <div
          class="filterSection"
        >
          <div class="keywordSection">
            <p class="dateLabel">Ticket number</p>
            <input
              id="ticketNumberSearch"
              v-model="ticketNumber"
              type="text"
              class="searchControl"
              minlength="8"
              maxlength="20"
            >
            <p
              class="dateLabel"
              style="margin-left:26px;">Key word search
            </p>
            <input
              id="keywordSearch"
              v-model="keywords"
              type="text"
              class="searchControl"
              minlength="2"
            >
          </div>
          <div class="keywordSection">
            <p class="dateLabel">Specific Site</p>
            <PortalDropdown
              id="siteSearch"
              :options="currentSites"
              :disabled="false"
              :max-item="999999"
              :attach-blur-event="true"
              :clear-box="resetSite"
              style="width: 180px;"
              placeholder="Select Site"
              tabindex="4"
              @selected="selectSite"

            />
            <p
              class="dateLabel"
              style="margin-left:26px;">Customer Reference
            </p>
            <input
              id="customerReferenceSearch"
              v-model="customerReference"
              type="text"
              class="searchControl"
              minlength="2"
            >
          </div>
          <div class="dateSelection">
            <p class="dateLabel">Last activity date</p>
            <dateControl
              id="activityFrom"
              v-model="activityFrom"
              :reset="resetDate"
              header="From"
              @date-control="setActivityFrom"
            />
            <dateControl
              id="activityTo"
              v-model="activityTo"
              :reset="resetDate"
              header="Until"
              @date-control="setActivityTo"
            />
          </div>
          <div class="dateSelection">
            <p class="dateLabel">Date created</p>
            <dateControl
              id="createdFrom"
              v-model="createdFrom"
              :reset="resetDate"
              header="From"
              @date-control="setCreatedFrom"
            />
            <dateControl
              id="createdTo"
              v-model="createdTo"
              :reset="resetDate"
              header="Until"
              @date-control="setCreatedTo"
            />
          </div>
          <div class="dateSelection">
            <p class="dateLabel">Completed Date</p>
            <dateControl
              id="completedFrom"
              v-model="completedFrom"
              :reset="resetDate"
              header="From"
              @date-control="setCompletedFrom"
            />
            <dateControl
              id="completedTo"
              v-model="completedTo"
              :reset="resetDate"
              header="Until"
              @date-control="setCompletedTo"
            />
          </div>
        </div>
        <div class="reloadTickets">
          <TextButton
            v-if="viewAreaTicketsPermission"
            :button-tabindex="2"
            button-id="btnReloadTickets"
            title="Clear Filter"
            text="Clear Filter"
            style="margin-right: 5px;"
            @click="clearFilter()"
          />
          <TextButton
            v-if="viewAreaTicketsPermission"
            :button-tabindex="2"
            button-id="btnReloadTickets"
            title="Apply Filter"
            text="Apply Filter"
            @click="loadTickets()"
          />
        </div>
      </div>
      <div class="appSectionBody scSectionBody">
        <div
          v-show="hasTickets"
          class="priorityFilterContainer"
        >
          <div
            :class="getPriorityColour('critical')"
            class="priorityIcon"
            @click="applyFilter('critical')"
          >
            Critical {{ `(${getTicketCount('Critical')})` }}
          </div>
          <div
            :class="getPriorityColour('high')"
            class="priorityIcon"
            @click="applyFilter('high')"
          >
            High {{ `(${getTicketCount('High')})` }}
          </div>
          <div
            :class="getPriorityColour('medium')"
            class="priorityIcon"
            @click="applyFilter('medium')"
          >
            Medium {{ `(${getTicketCount('Medium')})` }}
          </div>
          <div
            :class="getPriorityColour('low')"
            class="priorityIcon"
            @click="applyFilter('low')"
          >
            Low {{ `(${getTicketCount('Low')})` }}
          </div>
          <div
            :class="getPriorityColour('changes')"
            class="priorityIcon"
            @click="applyFilter('changes')"
          >
            Changes {{ `(${getTicketCount('Changes')})` }}
          </div>
          <div
            :class="getPriorityColour('ServiceRequest')"
            class="priorityIcon"
            @click="applyFilter('ServiceRequest')"
          >
            Service Request {{ `(${getTicketCount('ServiceRequest')})` }}
          </div>
          <div
            :class="getPriorityColour('')"
            class="priorityIcon"
            @click="applyFilter(undefined)"
          >
            All {{ `(${getTicketCount('')})` }}
          </div>
        </div>
        <div
          :class="{mdHalfScreen : !!selectedTicket}"
          class="mdTickets"
        >
          <div
            v-show="loadingTickets"
            class="divSpinner"
          >
            <img
              :src="maintelSpinnerImage"
              alt="loading tickets, please wait..."
            >
            <p class="pSpinnerText">loading ticket query, please wait...</p>
          </div>
          <table
            v-show="hasTickets && !loadingTickets"
            id="ticketsTable"
          >
            <thead />
            <tbody>
              <tr
                v-for="v in currentFilter"
                :key="v.id"
                :class="ticketClass(selected == v.id, v.Priority)"
                :tabindex="20"
                @click="selectTicket(v)"
                @keyup="testSelectTicket($event, v)"
              >
                <td :title="v.Ticket_Number">{{ v.Ticket_Number }}</td>
                <td :title="v.Priority">{{ v.Priority }}</td>
                <td
                  :title="v.Title">{{ v.Title }}
                </td>
                <td
                  :title="v.Date_Added">{{ v.Date_Added }}
                </td>
                <td
                  :title="v.Last_Activity">{{ v.Last_Activity }}
                </td>
                <td :title="v.Status">{{ v.Status }}</td>
              </tr>
            </tbody>
          </table>
          <div
            v-show="!hasTickets && !loadingTickets && filterSet"
            class="noResults"
          >
            Can’t find your ticket? Call our service desk on 0344 871 1122 (option 2 for support)
          </div>
          <div
            v-show="!hasTickets && !loadingTickets & !filterSet"
            class="noFilter"
          >
            Use the filter to configure and generate a report.
          </div>
        </div>
        <div
          v-if="!!selectedTicket"
          class="mdSelection"
        >
          <ticketDetailsComponent
            :ticket="selectedTicket"
            :full-ticket="selectedTicketFull"
            :show-details="showDetails"
            :show-new-notes="false"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!viewAreaTicketsPermission && !loadingTickets"
      class="noResults"
    >
      Sorry, you do not have permission to view tickets.
    </div>
  </div>
</template>

<script>
import { ticketMixin } from '../mixins/ticket'
import tableHandler from '../mixins/tableHandler'
import { permissionsMixin } from '../mixins/permissions'
import { autotaskAPIArchiveMixin } from '../mixins/autotaskAPIArchiveMixin'
import { mapActions } from 'vuex'
import { DataEventBus } from '../events/dataEvents'
import ticketDetailsComponent from '../components/tickets/ticketDetailsComponent'
import PortalDropdown from '../components/common/portalDropdown'
import dateControl from '../components/common/dateControl'
import TextButton from '../components/common/textButton.vue'

export default {
  metaInfo: {
    title: 'icon portal - Advanced Search'
  },
  components: {
    ticketDetailsComponent,
    TextButton,
    dateControl,
    PortalDropdown
  },
  mixins: [
    ticketMixin,
    tableHandler,
    permissionsMixin,
    autotaskAPIArchiveMixin
  ],
  data () {
    return {
      visibleTickets: [],
      archiveTickets: [],
      selectedTicket: null,
      selectedTicketFull: null,
      selected: null,
      dialog: false,
      displayCompletedTickets: false,
      displayIncompleteTickets: true,
      displayWaitingTickets: true,
      displayInProcessTickets: true,
      displayServiceRestoredTickets: true,
      loadingTickets: false,
      showDetails: false,
      resetSite: false,
      keywords: '',
      ticketNumber: '',
      site: '',
      customerReference: '',
      activityFrom: '',
      activityTo: '',
      createdFrom: '',
      createdTo: '',
      completedFrom: '',
      completedTo: '',
      showFilter: true,
      filterSet: false,
      reportTitle: '',
      noRecords: 0,
      resetDate: false,
      maxTicketResults: 100,
      maxTicketThresholdExceeded: false
    }
  },
  computed: {
    isLoggedOff () {
      return this.$store.getters.loggedOff
    },
    viewAreaTicketsPermission () {
      return this.$store.getters.viewAreaTicketsPermission
    },
    autotaskIcon () {
      return require(`../assets/icons/autotask-icon.png`)
    },
    currentSites () {
      return this.$store.getters.sites
    },
    loginBackgroundColour () {
      return `background-color: ${this.$store.getters.loginButtonColour}`
    },
    hasTickets () {
      return this.visibleTickets && this.visibleTickets.length > 0
    },
    maintelSpinnerImage () {
      return `/${this.$store.getters.channelCompany.imageRoot}/${this.$store.getters.spinnerImage}`
    },
    ticketLink () {
      if (this.$route.query.ticket) {
        return this.$route.query.ticket
      } else {
        return ''
      }
    },
    useAltColour () {
      var styles = this.$store.getters.entityStyles
      var rtn = true
      if (styles.length > 0) {
        for (let i = 0; i < styles.length; i++) {
          if (styles[i].styleName === 'alt-colour') {
            if (styles[i].styleValue === 'false') {
              rtn = false
            }
          }
        }
      }
      return rtn
    }
  },
  async mounted () {
    DataEventBus.$on('company-changed', () => {
      this.setUpTable([])
      this.loadingTickets = false
      this.visibleTickets = []
      this.currentFilter = []
      this.archiveTickets = []
      this.selectedTicket = null
      this.hasTickets = false
      this.filterSet = false
      this.reportTitle = ''
      this.showFilter = true
    })
    this.activityTo = this.formatDate(new Date().getTime() - (24 * 60 * 60 * 1000))
    this.createdTo = this.formatDate(new Date().getTime() - (24 * 60 * 60 * 1000))
    this.completedTo = this.formatDate(new Date().getTime() - (24 * 60 * 60 * 1000))
    this.setUpTable([])
    // await this.computeTickets()
    DataEventBus.$emit('still-active')
  },
  methods: {
    ...mapActions([
      'setPage',
      'setTickets'
    ]),
    formatDate (date) {
      var parsedDate = new Date(date)
      var day = parsedDate.getDate()
      var month = parsedDate.getMonth() + 1
      var year = parsedDate.getFullYear()
      if (month < 10) month = '0' + month
      if (day < 10) day = '0' + day
      return year + '-' + month + '-' + day
    },
    formatDateString (date) {
      var parsedDate = new Date(date)
      var day = parsedDate.getDate()
      var month = parsedDate.getMonth() + 1
      var year = parsedDate.getFullYear()
      if (month < 10) month = '0' + month
      if (day < 10) day = '0' + day
      return day + '/' + month + '/' + year
    },
    setUpTable (data) {
      this.initialiseTable('ticketsTable',
        data,
        ['Ticket_Number', 'Priority', 'Title', 'Date_Added', 'Last_Activity', 'Status'],
        ['Ticket Number', 'Priority', 'Title', 'Date Added', 'Last Activity', 'Status'],
        ['', '', '', 'Date', 'Date', '', ''],
        null,
        ['12%', '12%', '35%', '12%', '12%', '12%'],
        null,
        true,
        'maintel_archive_tickets.csv'
      )
    },
    selectTicket: async function (selectedRecord) {
      this.selectedTicketFull = this.archiveTickets.filter(t => t.id === selectedRecord.id)[0]
      if (this.selectedTicketFull.createdByContactId > 0) {
        var contact = await this.getContactById(this.selectedTicketFull.createdByContactId)
        if (contact.length > 0) {
          selectedRecord.Contact_Name = contact[0].firstName + ' ' + contact[0].lastName
        } else {
          selectedRecord.Contact_Name = 'Not Resolved (' + this.selectedTicketFull.createdByContactId + ')'
        }
      } else {
        selectedRecord.Contact_Name = 'Unknown'
      }
      // if (this.selectedTicketFull.assignedResourceId > 0) {
      //   var resource = await this.getAutotaskResourceById(this.selectedTicketFull.assignedResourceId)
      //   selectedRecord.Assigned_Resource = resource[0].firstName + ' ' + resource[0].lastName
      // } else {
      //   selectedRecord.Assigned_Resource = 'Unknown'
      // }
      this.selectedTicket = selectedRecord
      if (this.selected === selectedRecord.id) {
        this.selected = null
        this.selectedTicket = null
      } else {
        this.selected = selectedRecord.id
      }
      DataEventBus.$emit('still-active')
    },
    testSelectTicket (event, selectedRow) {
      if (event.keyCode === 32) {
        this.selectTicket(event, selectedRow)
      }
    },
    loadTickets: async function () {
      console.log('break')
      if (this.keywords.length === 0 &&
        this.ticketNumber.length === 0 &&
        this.site.length === 0 &&
        this.customerReference.length === 0 &&
        this.activityFrom.length === 0 &&
        this.createdFrom.length === 0 &&
        this.completedFrom.length === 0) {
        this.$store.commit('showMessage', { content: 'Please provide at least one criteria to search for.', color: 'red', timeout: 5000 })
      } else {
        this.filterSet = true
        await this.computeTickets()
      }
    },
    computeTickets: async function () {
      this.loadingTickets = true
      this.visibleTickets = []
      this.currentFilter = []
      this.archiveTickets = []
      this.reportTitle = ''
      if (this.viewAreaTicketsPermission) {
        this.archiveTickets = await this.ticketsGetArchived(this.keywords, this.ticketNumber, this.site, this.customerReference, this.activityFrom, this.activityTo, this.createdFrom, this.createdTo, this.completedFrom, this.completedTo)
        if (this.archiveTickets && this.archiveTickets.length > 0) {
          this.archiveTickets
            .forEach(element => {
              var custRef = ''
              if (element.customFields) {
                element.customFields.forEach(ref => {
                  if (ref.name === 'Customer Reference') {
                    custRef = ref.value
                  }
                })
              }
              var _tempTicket = {
                id: element.id,
                Title: element.title,
                Ticket_Number: element.ticketNumber,
                Date_Added: this.getScreenDate(element.createDateTime),
                Date_Due: this.getScreenDate(element.dueDateTime),
                Estimated_Hours: element.estimatedHours,
                Issue_Type: this.getIssueTypeFromId(element.issueTypeId),
                Sub_Issue_Type: this.getSubIssueTypeFromId(element.subIssueTypeId),
                Problem_Ticket_Id: element.problemTicketId,
                Last_Activity: this.getScreenDate(element.lastActivityDateTime),
                Status: this.getStatusFromId(element.statusId),
                StatusId: element.statusId,
                Priority: this.getPriorityFromId(element.priorityId),
                PriorityId: element.priorityId,
                Customer_Reference: custRef
              }
              if (element.accountId != null && element.accountId !== 0) {
                const siteObj = this.$store.getters.site(element.accountId)
                if (siteObj) {
                  _tempTicket.Site = siteObj.name
                }
              }
              this.visibleTickets.push(_tempTicket)
            })
          // this.visibleTickets = this.visibleTickets.sort(this.sortBy('-Ticket_Number'))
        }
        if (this.visibleTickets && this.visibleTickets.length > 0) {
          this.setUpTable(this.visibleTickets)
          this.noRecords = this.visibleTickets.length
          this.showFilter = false
          this.reportTitle = this.getReportTitle()
        }
        this.loadingTickets = false
      }
    },
    getStatusFromId (statusId) {
      if (statusId) {
        var rtn = this.$store.getters.statusList.find(s => s.value === statusId.toString())
        if (rtn) {
          return rtn.name
        } else {
          return 'Unknown'
        }
      } else {
        return 'Unknown'
      }
    },
    getPriorityFromId (priorityId) {
      if (priorityId) {
        var rtn = this.$store.getters.priorityList.find(p => p.value === priorityId.toString())
        if (rtn) {
          return rtn.name
        } else {
          return 'Unknown'
        }
      } else {
        return 'Unknown'
      }
    },
    getIssueTypeFromId (issueTypeId) {
      if (issueTypeId) {
        var rtn = this.$store.getters.issueTypeList.find(p => p.value === issueTypeId.toString())
        if (rtn) {
          return rtn.name
        } else {
          return 'Unknown'
        }
      } else {
        return 'Unknown'
      }
    },
    getSubIssueTypeFromId (subIssueTypeId) {
      if (subIssueTypeId) {
        var rtn = this.$store.getters.subIssueTypeList.find(p => p.value === subIssueTypeId.toString())
        if (rtn) {
          return rtn.name
        } else {
          return 'Unknown'
        }
      } else {
        return 'Unknown'
      }
    },
    ticketClass (selected, priority) {
      var tClass = 'Grey'
      if (priority.indexOf('Major Incident') > -1 || priority.indexOf('Critical') > -1) {
        tClass = 'Purple'
      } else if (priority.indexOf('High') > -1) {
        tClass = 'Red'
      } else if (priority.indexOf('Low') > -1) {
        tClass = 'Green'
      } else if (priority.indexOf('Medium') > -1) {
        tClass = 'Amber'
      } else if (priority.indexOf('Change') > -1) {
        tClass = 'Brown'
      } else if (priority === 'P5 - Service Request') {
        tClass = 'Blue'
      }
      if (!this.useAltColour) {
        tClass = 'Solid' + tClass
      }
      return tClass
    },
    getPriorityColour: function (priority) {
      var isSelectedPriority = this.$route.query.ticketsFilter === priority ? 'selected' : ''
      if (this.$route.query.ticketsFilter === undefined && priority.length === 0) {
        isSelectedPriority = 'selected'
      }
      switch (priority ? priority.toLowerCase() : '') {
        case 'servicerequest':
          return 'Blue ' + isSelectedPriority
        case 'critical':
          return 'Purple ' + isSelectedPriority
        case 'high':
          return 'Red ' + isSelectedPriority
        case 'medium':
          return 'Amber ' + isSelectedPriority
        case 'low':
          return 'Green ' + isSelectedPriority
        case 'changes':
          return 'Brown ' + isSelectedPriority
        default:
          return 'Grey ' + isSelectedPriority
      }
    },
    getSelectedPriority (filterPriority) {
      if (!filterPriority) {
        filterPriority = this.$route.query.ticketsFilter
      }
      var selectedPriorityId = []
      if (filterPriority) {
        if (filterPriority.toLowerCase() === 'servicerequest') {
          selectedPriorityId.push(22)
        } else if (filterPriority.toLowerCase() === 'critical') {
          selectedPriorityId.push(4)
          selectedPriorityId.push(5)
          selectedPriorityId.push(6)
          selectedPriorityId.push(11)
        } else if (filterPriority.toLowerCase() === 'high') {
          selectedPriorityId.push(1)
          selectedPriorityId.push(7)
          selectedPriorityId.push(12)
        } else if (filterPriority.toLowerCase() === 'medium') {
          selectedPriorityId.push(2)
          selectedPriorityId.push(8)
          selectedPriorityId.push(13)
        } else if (filterPriority.toLowerCase() === 'low') {
          selectedPriorityId.push(3)
          selectedPriorityId.push(9)
        } else if (filterPriority.toLowerCase() === 'changes') {
          selectedPriorityId.push(14)
          selectedPriorityId.push(15)
          selectedPriorityId.push(23)
          selectedPriorityId.push(24)
        } else {
          for (let i = 0; i < 25; i++) {
            selectedPriorityId.push(i)
          }
        }
      } else {
        for (let i = 0; i < 25; i++) {
          selectedPriorityId.push(i)
        }
      }
      return selectedPriorityId
    },
    getExclusionStatusList () {
      var exclusionList = []
      var waitingList = [7, 9, 12, 13, 24, 25, 32, 33, 55, 56, 57, 59, 60, 61, 63, 68, 80, 85, 89]
      var inProcessList = [8, 92]
      var serviceRestoredList = [35, 66]
      if (!this.displayCompletedTickets) {
        exclusionList = [5, 16, 69, 72, 93, 94, 95, 99]
      }
      if (!this.displayIncompleteTickets) {
        for (let i = 1; i < 101; i++) {
          if (i !== 5 && i !== 16 && i !== 69 && i !== 72 && i !== 93 && i !== 94 && i !== 95 && i !== 99) {
            exclusionList.push(i)
          }
        }
      }
      if (this.displayWaitingTickets) {
        waitingList.forEach(item => {
          if (exclusionList.includes(item)) {
            var index = exclusionList.indexOf(item)
            if (index !== -1) {
              exclusionList.splice(index, 1)
            }
          }
        })
      }
      if (this.displayInProcessTickets) {
        inProcessList.forEach(item => {
          if (exclusionList.includes(item)) {
            var index = exclusionList.indexOf(item)
            if (index !== -1) {
              exclusionList.splice(index, 1)
            }
          }
        })
      }
      if (this.displayServiceRestoredTickets) {
        serviceRestoredList.forEach(item => {
          if (exclusionList.includes(item)) {
            var index = exclusionList.indexOf(item)
            if (index !== -1) {
              exclusionList.splice(index, 1)
            }
          }
        })
      }
      if (!this.displayWaitingTickets) {
        waitingList.forEach(item => {
          if (!exclusionList.includes(item)) { exclusionList.push(item) }
        })
      }
      if (!this.displayInProcessTickets) {
        inProcessList.forEach(item => {
          if (!exclusionList.includes(item)) { exclusionList.push(item) }
        })
      }
      if (!this.displayServiceRestoredTickets) {
        serviceRestoredList.forEach(item => {
          if (!exclusionList.includes(item)) { exclusionList.push(item) }
        })
      }
      return exclusionList
    },
    applyFilter: function (ticketsFilter) {
      var selectedPriorityList = this.getSelectedPriority(ticketsFilter)
      this.setUpTable(this.visibleTickets.filter(t => selectedPriorityList.includes(t.PriorityId)))
    },
    clearFilter () {
      this.resetSite = !this.resetSite
      this.keywords = ''
      this.ticketNumber = ''
      this.site = ''
      this.customerReference = ''
      this.activityFrom = ''
      this.activityTo = ''
      this.createdFrom = ''
      this.createdTo = ''
      this.completedFrom = ''
      this.completedTo = ''
      this.resetDate = true
    },
    getTicketCount: function (priority) {
      if (!this.visibleTickets) {
        return 0
      } else {
        if (this.visibleTickets.length === 0) {
          return 0
        } else {
          if (priority.length > 0) {
            var selectedPriorityList = this.getSelectedPriority(priority)
            return this.visibleTickets
              .filter(t => selectedPriorityList.includes(t.PriorityId)).length
          } else {
            return this.visibleTickets.length
          }
        }
      }
    },
    filterTicketStatus (src) {
      if (src === 'incomplete') {
        this.displayIncompleteTickets = !this.displayIncompleteTickets
      }
      if (src === 'completed') {
        this.displayCompletedTickets = !this.displayCompletedTickets
      }
      if (src === 'inprocess') {
        this.displayInProcessTickets = !this.displayInProcessTickets
      }
      if (src === 'waiting') {
        this.displayWaitingTickets = !this.displayWaitingTickets
      }
      if (src === 'servicerestored') {
        this.displayServiceRestoredTickets = !this.displayServiceRestoredTickets
      }
      this.visibleTickets = []
      this.selectedTicket = null
      this.computeTickets()
    },
    setDialog: function (value) {
      this.dialog = value
    },
    getScreenDate (dte) {
      var rtn = ''
      if (dte) {
        var date = new Date(dte)
        var day = this.checkForZero(date.getDate())
        var year = this.checkForZero(date.getFullYear())
        var month = this.checkForZero(date.getMonth() + 1)
        var hours = this.checkForZero(date.getHours())
        var minutes = this.checkForZero(date.getMinutes())
        rtn = day + '-' + month + '-' + year + ' ' + hours + ':' + minutes
      }
      return rtn
    },
    checkForZero (int) {
      if (int.toString().length === 1) {
        int = '0' + int
      }
      return int
    },
    openLinkedTicket: async function () {
      var element = await this.getTicketById(this.ticketLink)
      var tempTicket = {
        id: element.id,
        Title: element.title,
        Ticket_Number: element.ticketNumber,
        Date_Added: this.getScreenDate(element.createDateTime),
        Date_Due: this.getScreenDate(element.dueDateTime),
        Estimated_Hours: element.estimatedHours,
        Issue_Type: this.getIssueTypeFromId(element.issueTypeId),
        Sub_Issue_Type: this.getSubIssueTypeFromId(element.subIssueTypeId),
        Problem_Ticket_Id: element.problemTicketId,
        Last_Activity: this.getScreenDate(element.lastActivityDateTime),
        Status: this.getStatusFromId(element.statusId),
        Priority: this.getPriorityFromId(element.priorityId)
      }
      if (element.accountId != null && element.accountId !== 0) {
        const siteObj = this.$store.getters.site(element.accountId)
        if (siteObj) {
          tempTicket.Site = siteObj.name
        }
      }
      this.selectedTicket = tempTicket
      this.selected = this.ticketLink
    },
    openTicketPage () {
      this.$router.push('/tickets')
    },
    toggleFilter () {
      this.showFilter = !this.showFilter
    },
    setCreatedFrom (data) {
      this.resetDate = false
      this.createdFrom = data
      this.checkDates()
    },
    setCreatedTo (data) {
      this.resetDate = false
      this.createdTo = data
      this.checkDates()
    },
    setCompletedFrom (data) {
      this.resetDate = false
      this.completedFrom = data
      this.checkDates()
    },
    setCompletedTo (data) {
      this.resetDate = false
      this.completedTo = data
      this.checkDates()
    },
    setActivityFrom (data) {
      this.resetDate = false
      this.activityFrom = data
      this.checkDates()
    },
    setActivityTo (data) {
      this.resetDate = false
      this.activityTo = data
      this.checkDates()
    },
    getReportTitle () {
      var title = ''
      if (this.noRecords > 0) {
        if (this.keywords.length > 0) {
          title += ' key words contains "' + this.keywords + '"'
        }
        if (this.ticketNumber.length > 0) {
          if (title.length > 0) { title += ' and ' }
          title += ' ticket number contains "' + this.ticketNumber + '"'
        }
        if (this.customerReference.length > 0) {
          if (title.length > 0) { title += ' and ' }
          title += ' customer reference contains "' + this.customerReference + '"'
        }
        if (this.site > 0) {
          if (title.length > 0) { title += ' and ' }
          title += ' site identifier "' + this.site + '"'
        }
        if (this.activityFrom.length > 0) {
          if (title.length > 0) { title += ' and ' }
          title += ' activity from: "' + this.formatDateString(this.activityFrom) + '"'
          if (this.activityTo.length > 0) {
            if (title.length > 0) { title += ' and ' }
            title += ' activity until: "' + this.formatDateString(this.activityTo) + '"'
          }
        }
        if (this.createdFrom.length > 0) {
          if (title.length > 0) { title += ' and ' }
          title += ' created from: "' + this.formatDateString(this.createdFrom) + '"'
          if (this.createdTo.length > 0) {
            if (title.length > 0) { title += ' and ' }
            title += ' created until: "' + this.formatDateString(this.createdTo) + '"'
          }
        }
        if (this.completedFrom.length > 0) {
          if (title.length > 0) { title += ' and ' }
          title += ' completed from: "' + this.formatDateString(this.completedFrom) + '"'
          if (this.completedTo.length > 0) {
            if (title.length > 0) { title += ' and ' }
            title += ' completed until: "' + this.formatDateString(this.completedTo) + '"'
          }
        }
        var thresholdMessage = this.maxTicketThresholdExceeded ? 'Please refine your search. More than ' : ''
        title = thresholdMessage + this.noRecords + ' tickets returned with' + title
      }
      return title
    },
    checkDates () {
      if (this.activityFrom.length > 0 && this.activityTo.length > 0) {
        if (new Date(this.activityFrom) - new Date(this.activityTo) > 0) {
          this.$store.commit('showMessage', { content: 'The activity from date should be earlier than the until date.', color: 'red', timeout: 3000 })
        }
      }
      if (this.createdFrom.length > 0 && this.createdTo.length > 0) {
        if (new Date(this.createdFrom) - new Date(this.createdTo) > 0) {
          this.$store.commit('showMessage', { content: 'The created from date should be earlier than the until date.', color: 'red', timeout: 3000 })
        }
      }
      if (this.completedFrom.length > 0 && this.completedTo.length > 0) {
        if (new Date(this.completedFrom) - new Date(this.completedTo) > 0) {
          this.$store.commit('showMessage', { content: 'The completed from date should be earlier than the until date.', color: 'red', timeout: 3000 })
        }
      }
    },
    selectSite: function (site) {
      if (site) {
        if (site.id) {
          this.site = site.id
        }
      }
    }
  }
}
</script>
<style scoped>
  .openIcon {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  .keywordSection {
    margin-bottom: 10px;
  }
  .filterSection {
    margin-left: 15px;
    width: calc(100% - 198px);
    display: inline-block;
  }
  .filterTitle {
    font-weight: bold;
  }
  .filterDate {
    width: 110px;
    margin-right: 20px;
  }
  .filterShow {
    display: inline-block;
  }
  .showFilterButton {
    box-shadow: 2px 4px 3px rgb(0 0 0 / 30%) !important;
    border-radius: 5px;
    display: inline-block;
    margin-top: 7px;
  }
  .dateSelection {
    display: inline-block;
  }
  .reloadTickets {
    display: inline-block;
    vertical-align: top;
    text-align: right;
    width: 100%;
    padding: 0 6px 16px 0;
  }
  .openTickets {
    margin: 0 5px 0 10px;
    text-align: left;
  }
  .noFilter {
    text-align: center;
    margin: 20px 0 0 0;
  }
  .appSectionContainer {
    margin-bottom: 40px;
  }

  .Brown {
    background-color:  rgb(193, 183, 164);
    border: 2px rgb(155, 139, 116) solid;
  }

  .Blue {
    background-color: rgb(137, 194, 255);
    border: 2px rgb(98, 147, 221) solid;
  }

  .Red {
    background-color: rgb(235, 102, 100);
    border: 2px rgb(241, 31, 27) solid;
  }

  .Amber {
    background-color: rgb(255, 172, 49);
    border: 2px rgb(240, 150, 16) solid;
  }

  .Green {
    background-color: rgb(102, 187, 106);
    border: 2px rgb(60, 165, 65) solid;
  }

  .Grey {
    background-color: rgb(189, 188, 188);
    border: 2px rgb(168, 167, 167) solid;
  }

  .Purple {
    background-color: rgb(182, 110, 241);
    border: 2px rgb(155, 48, 243) solid;
  }

  .priorityFilterContainer {
    display: flex;
  }

  .priorityIcon {
    flex: 1;
    margin: 5px 5px 10px 5px;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    font-weight: 400;
    font-size: 0.86em;
    cursor: pointer;
    box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    /* text-shadow: 2px 2px 2px rgb(156 147 147 / 87%); */

  }

  .selected {
    color: #fff;
  }

  .appSectionHeader {
    width: 100%;
    height: 50px;
    text-align: right;
  }

  .completedStatus {
    display:inline-block;
    margin-right: 15px;
  }

  .completedStatusText {
    display:inline-block;
    font-weight: 500;
    vertical-align: top;
  }

  .divSpinner {
    text-align: center;
    margin-top: 60px;
  }

  .pSpinnerText {
    font-weight: bold;
  }
i {
  color: black;
}
.dateSelection {
  display: block;
}
.dateLabel {
  width: 140px;
  display: inline-block;
  line-height: 1.5;
  font-weight: 300;
  font-size: 16px;
}
.titleContainer {
  display: inline-block;
  width: calc(100% - 126px);
  text-align: left;
  font-weight: 500;
  padding-left: 6px;
}
.titleSection {
  font-size: 1em;
}
input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  background-color: #fff !important;
}
.textButton {
  vertical-align: top;
}
.v-btn.v-btn--icon {
  width: 32px;
  height: 22px;
}
</style>
